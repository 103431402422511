import React, { useMemo } from 'react';
import { WithTranslationsProps, withTranslations } from 'react-utilities';
import { AXAnalyticsService } from 'Roblox';
import translationConfig from '../../translation.config';
import { Category, Subcategory } from '../../constants/types';
import CatalogFilter, { TFilterOption } from './filterDropdown/CatalogFilter';

export type CategoryDropdownProps = {
  categories: Category[] | undefined;
  currentCategory: Category | undefined;
  currentSubcategory: Subcategory | null | undefined;
  updateCategory: (
    event: React.MouseEvent | null,
    isSubcategory: boolean,
    isSelected: boolean,
    category: Category,
    subcategory?: Subcategory
  ) => void;
  defaultCategory?: Category;
};

function CategoryDropdown({
  categories,
  currentCategory,
  currentSubcategory,
  updateCategory,
  defaultCategory,
  translate,
  intl
}: CategoryDropdownProps & WithTranslationsProps): JSX.Element | null {
  const filters: TFilterOption<number>[] = useMemo(() => {
    return (
      categories?.map(category => {
        const filterOption: TFilterOption<number> = {
          optionId: category.categoryId,
          optionDisplayName: category.name,
          optionValue: category.categoryId,
          subOptions:
            category.subcategories?.map(subcategory => {
              return {
                optionId: subcategory.subcategoryId,
                optionDisplayName: subcategory.name,
                optionValue: subcategory.subcategoryId
              };
            }) || []
        };
        return filterOption;
      }) || []
    );
  }, [categories]);

  if (!currentCategory) {
    return null;
  }

  if (!defaultCategory) {
    const itemName = `CategoryDropdown_NoDefault`;
    const log = JSON.stringify({
      message: 'Unable to render CategoryDropdown because defaultCategory was not found!'
    });
    // Report the error
    // We need to specify container name as counters name so we can build the graphs on grafana
    AXAnalyticsService.reportAXError({ itemName, counterName: 'CategoryDropdownError', log });

    return null;
  }

  return (
    <CatalogFilter<number>
      filter={{
        filterDropdownName: translate('Heading.CatalogCategory'),
        filterDisplayName: currentSubcategory?.name || currentCategory?.name || '',
        filterOptions: filters,
        selectedOptionId: currentSubcategory
          ? currentSubcategory.subcategoryId
          : currentCategory.categoryId,
        defaultOptionId: defaultCategory.categoryId
      }}
      updateFilterValue={newOptionValue => {
        let matchingCategory: Category | undefined;
        let matchingSubcategory: Subcategory | undefined;
        // eslint-disable-next-line no-restricted-syntax
        for (const category of categories || []) {
          if (newOptionValue?.optionId === category.categoryId) {
            matchingCategory = category;
            break;
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const subcategory of category.subcategories || []) {
            if (newOptionValue?.optionId === subcategory.subcategoryId) {
              matchingSubcategory = subcategory;
              matchingCategory = category;
              break;
            }
          }
        }

        if (!matchingCategory) {
          // Should always be a category
          return;
        }

        const isSubcategory = !!matchingSubcategory;
        const isCurrentlySelected = matchingSubcategory
          ? currentSubcategory?.subcategoryId === matchingSubcategory.subcategoryId
          : currentCategory?.categoryId === matchingCategory.categoryId;

        updateCategory(
          null,
          isSubcategory,
          isCurrentlySelected,
          matchingCategory,
          matchingSubcategory
        );
      }}
      showDivider
      translate={translate}
      intl={intl}
    />
  );
}

export default withTranslations(CategoryDropdown, translationConfig);
